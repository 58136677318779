import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Meta from 'vue-meta'
import RentRoot from '@/views/RentRoot.vue'
import RentOne from '@/views/RentOne.vue'
import RentTwo from '@/views/RentTwo.vue'

import WorksRoot from '@/views/WorksRoot.vue'
import WorksOne from '@/views/WorksOne.vue'
import WorksTwo from '@/views/WorksTwo.vue'

Vue.use(VueRouter)
Vue.use(Meta)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import( '../views/Home.vue'),
    meta: { headerSection: 'home' },
    //meta: { title: 'Фотограф беременности Краснодар' }
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      //title: 'Фотограф беременности Краснодар',
      headerSection: 'about'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/works',
    name: 'Works',
    component: WorksRoot,
    children: [
      {
        path: 'one',
        name: 'one',
        meta: { headerSection: 'works' },
        component: WorksOne
      },
      {
        path: 'two',
        name: 'two',
        meta: { headerSection: 'works' },
        component: WorksTwo
      }
    ]
  },
  {
    path: '/prices',
    name: 'Prices',
    meta: { headerSection: 'prices' },
    //meta: { title: 'Фотограф Краснодар цены (недорого)' },
    component: () => import( '../views/Prices.vue')
  },
  {
    path: '/backstage',
    name: 'Backstage',
    meta: {
      headerSection: 'backstage',
      title: 'backstage',
      description: 'backstage',
    },
    //meta: { title: 'Фотограф Краснодар' },
    component: () => import( '../views/Backstage.vue')
  },
  {
    path: '/rent',
    name: 'Rent',
    //meta: { title: 'Фотограф беременности Краснодар недорого' },
    component: RentRoot,
    children: [
      {
        path: 'minimalism',
        name: 'minimalism',
        meta: { headerSection: 'rent' },
        component: RentOne
      },
      {
        path: 'studio',
        name: 'studio',
        meta: { headerSection: 'rent' },
        component: RentTwo
      }
    ]
  },
  {
    path: '/variants',
    name: 'Variants',
    //meta: { title: 'Фотограф беременности Краснодар' },
    component: () => import( '../views/Variants.vue')
  },
  {
    path: "/page-not-found",
    alias: '*',
    component: { render: (h) => h("div", ["404! Page Not Found!"]) },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

/* router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
 
}) */


export default router

<template>
  <div class="home">
    <div class="home-main-info">
      <h1>Фотограф беременных Краснодар</h1>
      <p>
        Фотосессия беременности в Краснодаре, свой прокат одежды, выбор студии
        <a
          href="https://www.tovkes-foto.ru/rent/one"
        >
          Прокат одежды
        </a>
      </p>
    </div>
    <span class="home_title"></span>
    <div class="home_data d-flex flex-column flex-sm-row flex-nowrap align-items-center align-items-sm-start">
      <div class="about_logo">
        <img
          class=""
          alt="Лучший фотограф Краснодар"
          title="Фотосессия беременности"
          src="../assets/myself.jpg"
          >
      </div>
      <div class="about_text">
        <ul class="d-flex flex-column align-items-start">
          <li>Фотосессия беременности "Всё включено" за 8500.</li>
          <li>Свой прокат одежды</li>
          <li>70+ лучших кадров в цветокоррекции</li>
          <li>Фото уже через 10 дней</li>
          <li class="backstage">
            <router-link to="/backstage" class="backstage_link d-flex flex-column align-items-center justify-content-around">
              <div>как</div>
              <div>проходит</div>
              <div>съемка</div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="home_advantages mt-5">
      <p class="home_advantages__title mb-5">7 причин почему мамочки выбирают меня:</p>
      <ul class="home_advantages__list text-start list-group">
        <li class="home_advantages__item">
          <strong>Съемка "под ключ".</strong><br> Это означает, что ты не будешь тратить время на подбор студии, визажиста, 
          фотографа и покупку одежды. Мы подберем и забронируем студию, гримерку и привезем одежду в студию. 
          Тебе остается лишь прийти.
        </li>
        <li class="home_advantages__item  ">
          <strong>Макияж и укладка на любой вкус.</strong><br>Визажист делает полный образ от 45 минут, что очень удобно, 
          если ты не можешь долго сидеть на одном месте. Ты можешь выбрать любой макияж и локоны.
        </li>
        <li class="home_advantages__item  ">
          <strong>Индивидуальный подбор одежды.</strong><br>Одежда подбирается с учетом твоих особенностей. 
          Мы обсуждаем заранее все образы и составляем концепцию съемки. Если ты не увидела в моем гардеробе 
          нужный образ, я всегда помогу собрать образ из твоей одежды.
        </li>
        <li class="home_advantages__item  ">
          <strong>Участие мужа и детей без доплаты.</strong><br> Ты можешь взять на съемку мужа, детей и других родственников. 
          За это не берется дополнительная плата.
        </li>
        <li class="home_advantages__item  ">
          <strong>Готовые фото уже через 10 дней</strong><br>Ты получишь более 70+ фото в авторской цветокоррекции (количество 
          фото в ретуши зависит от пакета)
        </li>
        <li class="home_advantages__item  ">
          <strong>Помощь в позировании</strong><br> У меня есть собственный сценарий проведения съемки, по которому 
          мы получим множество разнообразных кадров, даже если ты впервые на профессиональной фотосессии и 
          не умеешь позировать.
        </li>
        <li class="home_advantages__item  ">
          <strong>Тысячи довольных мам</strong>
        </li>
      </ul>
    </div>
    <div class="home_reviews"></div>
  </div>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'Home',
  metaInfo: {
    title: 'Фотограф беременных Краснодар',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'фотосессия для беременных в студии'
      },
      {
        vmid: 'keywords',
        name: 'keywords',
        content: 'фото, беременная, фотоальбом, фотограф, выписка, роддом'
      },
    ],
  },
  mounted(){
    setTimeout(this.removeNah, 500)
  },
  methods: {
    removeNah(){
      // document.body.removeChild(document.body.lastChild);
      const elements = document.getElementsByClassName("freehosting-2domains-link")
      while(elements.length > 0){
        elements[0].parentNode.removeChild(elements[0])
      }
    }
  } 
}
</script>

<style lang="scss">
  @import "~bootstrap/scss/_functions.scss";
  @import "~bootstrap/scss/_variables.scss";
  @import "~bootstrap/scss/mixins/_breakpoints.scss";

  @font-face {
    font-family: "Playfair";
    src: local("PlayfairDisplay-Regular"),   url(../fonts/PlayfairDisplay-Regular.ttf) format("truetype");
  }
  @font-face {
    font-family: "Caveat";
    src: local("Caveat"),   url(../fonts/Caveat-Regular.ttf) format("truetype");
  }
  .home{
    font-family: Playfair, sans-serif;

    &-main-info {
      //visibility: hidden;
      h1, p, a {
        color: transparent;
        font-size: 1px;
      }
    }
    &_title{
      font-size: 32px;
    }
    &_data{
      .about_logo{
        width: 50%;
        border-radius: 30%;
        box-shadow: inset 0px 0px 10px rgba(73, 162, 221, 0.5);
        img{
          width: 100%;
          height: auto;
          border-radius: 50%;
        }
      }
      .about_text{
        margin-left: 135px;
        margin-left: 7%;
        margin-top: 20px;
        font-size: 16px;
        text-align: left;
        height: 100%;
        ul{
          gap: 30px;
          li{
            font-size: 34px;
            font-family: Playfair;
            @include media-breakpoint-down(sm) {
              font-size: 24px;
            }
          }
          .backstage{
            box-shadow: inset 0px 0px 10px rgba(73, 162, 221, 0.5);
            background: transparent;
            border: 1px solid #000;
            border-radius: 50%;
            margin-top: 30px;
            cursor: pointer;
            align-self: center;
            margin-left: 35%;
            padding: 15px;
            list-style-type: none;
            animation: none;
            &:hover{
              animation: pulse 1s infinite;
            }
            a{              
              color: #414042;
              text-decoration: none;
            }
            @include media-breakpoint-down(md) {
              margin-left: -10%;
            }
          }
        }
      }
    }
    &_advantages{
      &__title{
        font-size: 42px;
        font-weight: 600;
        @include media-breakpoint-down(sm) {
          font-size: 36px;
        }
        //font-family: Caveat, sans-serif;
      }
      &__list{
        list-style-type: none;
        margin-left: 5%;
      }
      &__item{
        margin-bottom: 45px;
        font-size: 26px;
        @include media-breakpoint-down(sm) {
          font-size: 20px;
        }
        &::before{
          content: " \2605";
          color: goldenrod;
          margin-right: 15px;
        }
      }
    }
  }

  @-webkit-keyframes pulse {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(73, 162, 221, 0.5);
	}
	70% {
		-webkit-box-shadow: 0 0 0 20px rgba(204,169,44, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
	}
}

@keyframes pulse {
	0% {
		-moz-box-shadow: 0 0 0 0 rgba(73, 162, 221, 0.5);
		box-shadow: 0 0 0 0 rgba(73, 162, 221, 0.5);
	}
	70% {
		-moz-box-shadow: 0 0 0 20px rgba(204,169,44, 0);
		box-shadow: 0 0 0 20px rgba(204,169,44, 0);
	}
	100% {
		-moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
		box-shadow: 0 0 0 0 rgba(204,169,44, 0);
	}
}
</style>

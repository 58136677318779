import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueYandexMetrika from 'vue-yandex-metrika' 
import store from './store'
import VueLazyLoad from 'vue-lazyload'

import { BootstrapVue, IconsPlugin, } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueLazyLoad)

Vue.use(VueYandexMetrika, {
    id: 87950065,
    router: router,
    env: 'production'
})


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

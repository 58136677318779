<template>
  <div class="works">
    <!-- <h3>галерея</h3>
    <span>Съемка в студиях с профессиональным светом, фонами, циклорамой, стильная фотосессия беременности!</span> -->
      <div class="works_gallery" id="works_gallery">
        <div 
          v-for="i in items"
          :key="'gallery_'+i"
          class="gallery_items"
        > 
          <template 
            v-if="i == 'Item1' "
            class=""
          >
              <div class="gallery_item">
              <img
                
                
                alt="potograpaher_logo"
                src="../assets/images/gallery/interior/1.jpg"
                >
            </div>
              <div class="gallery_item">
              <img
                
                
                alt="potograpaher_logo"
              src="../assets/images/gallery/interior/2.jpg"
              >
              </div>
              <div class="gallery_item gallery_item__big">
              <img
                
                
                alt="potograpaher_logo"
              src="../assets/images/gallery/interior/3.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
                
                
                alt="potograpaher_logo"
              src="../assets/images/gallery/interior/4.jpg"
              >
              </div>
              <div class="gallery_item gallery_item__big">
              <img
                
                
                alt="potograpaher_logo"
              src="../assets/images/gallery/interior/5.jpg"
              >
              </div>
              <div class="gallery_item ">
              <img
                
                
                alt="potograpaher_logo"
              src="../assets/images/gallery/interior/6.jpg"
              >
              </div>
              <div class="gallery_item ">
              <img
                
                
                alt="potograpaher_logo"
              src="../assets/images/gallery/interior/7.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
                
                
                alt="potograpaher_logo"
              src="../assets/images/gallery/interior/8.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
                
                
                alt="potograpaher_logo"
              src="../assets/images/gallery/interior/9.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/interior/10.jpg"
              >
              </div>
              <div class="gallery_item gallery_item__big">
              <img     
                alt="potograpaher_logo"
              src="../assets/images/gallery/interior/11.jpg"
              >
              </div>
              <div class="gallery_item gallery_item__big">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/interior/12.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/interior/13.jpg"
              >
              </div>
              
          </template>
           <template 
            v-if="i == 'Item1' "
            class=""
           >             
              <div class="gallery_item">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/interior/14.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/interior/15.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/interior/16.jpg"
              >
              </div>
              <div class="gallery_item gallery_item__big">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/interior/17.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/interior/18.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/interior/19.jpg"
              >
              </div>
              <div class="gallery_item ">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/interior/20.jpg"
              >
          </div>
        </template>
        <template 
            v-if="i == 'Item1' "
            class=""
           >             
              <div class="gallery_item ">
              <img     
                alt="potograpaher_logo"
              src="../assets/images/gallery/interior/21.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/interior/22.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/interior/23.jpg"
              >
              </div>
              <div class="gallery_item gallery_item__big">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/interior/24.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/interior/25.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/interior/26.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/interior/27.jpg"
              >
              </div>
              <div class="gallery_item ">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/interior/28.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/interior/29.jpg"
              >
              </div>
              <div class="gallery_item">
                <img
                  alt="potograpaher_logo"
                src="../assets/images/gallery/interior/30.jpg"
                >
              </div>
              <div class="gallery_item ">
                <img     
                  alt="potograpaher_logo"
                src="../assets/images/gallery/interior/31.jpg"
                >
              </div>
              <div class="gallery_item">
                <img
                  alt="potograpaher_logo"
                src="../assets/images/gallery/interior/32.jpg"
                >
              </div>
              <div class="gallery_item">
                <img
                  alt="potograpaher_logo"
                src="../assets/images/gallery/interior/48.jpg"
                >
              </div>
              <div class="gallery_item ">
                <img     
                  alt="potograpaher_logo"
                src="../assets/images/gallery/interior/49.jpg"
                >
              </div>
              <div class="gallery_item">
                <img
                  alt="potograpaher_logo"
                src="../assets/images/gallery/interior/50.jpg"
                >
              </div>
        </template>
        <template 
            v-if="i == 'Item1' "
            class=""
           >             
              
              <div class="gallery_item gallery_item__big">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/interior/33.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/interior/34.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/interior/35.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/interior/36.jpg"
              >
              </div>
              <div class="gallery_item ">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/interior/37.jpg"
              >
              </div>
              <div class="gallery_item ">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/interior/38.jpg"
              >
              </div>
              <div class="gallery_item ">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/interior/39.jpg"
              >
              </div>
              <div class="gallery_item ">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/interior/40.jpg"
              >
              </div>
              <div class="gallery_item gallery_item__big">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/interior/41.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/interior/42.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/interior/43.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/interior/44.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/interior/45.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/interior/46.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/interior/47.jpg"
              >
              </div>
        </template>
      </div>
      </div>
  </div>
</template>

<script>

export default {
  name: 'WorksTwo',
  metaInfo: {
    title: 'Интерьерная съемка Краснодар',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'стильная фотосессия в Краснодаре'
      }
    ],
  }, 
  data() {
    return{
      scrolled: false,
      nextItem: 1,
      items: []    
    }
  },
  mounted(){
    /* const gallery = document.querySelector('.works_gallery');
    gallery.addEventListener('scroll', e => {
      if(gallery.scrollTop + gallery.clientHeight >= gallery.scrollHeight){        
        this.loadMore();
      }
    } ) */

    this.loadMore();
  },
  methods: {
    loadMore(){
      if(this.nextItem < 5){
        setTimeout( e => {        
          for(let i =0; i < 1;  i++){
            this.items.push('Item' + this.nextItem++);
          }
        }, 200);
      }
    }
  }
}
</script>

<style lang="scss">
  .works_gallery{
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .gallery_items{
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    .gallery_item{
      position: relative;
      padding: 0px 0px 85% 0px;
      min-height: 550px;
      img{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .gallery_item__big{
      grid-column: span 2;
      grid-row: span 2;
    }
  }
</style>

<template>
  <div id="app">
    <div class="main_wrapper  d-flex flex-column">
      <!--div class="preheader d-none d-sm-block">
      </!--div-->
      <div class="header ">
        <Header/>
      </div>      
      <div class="content p-1 p-sm-3 mb-0 flex-grow-1">
         <transition name="fade">
          <router-view />
        </transition>
      </div>
      <div class="footer ">
        <Footer/>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    Header,
    Footer
  },
  /* beforeCreate(){
    document.title = 'Фотограф беременности Краснодар';
  }, */
  mounted(){
    //document.title = 'Фотограф беременности Краснодар';
    /* need while not on paid hosting */
    setTimeout(this.removeNah, 500)
  },
  methods: {
    removeNah(){
      // document.body.removeChild(document.body.lastChild);
      const elements = document.getElementsByClassName("freehosting-2domains-link")
      while(elements.length > 0){
        elements[0].parentNode.removeChild(elements[0])
    }
    }
  } 
}
</script>

<style src="@/assets/styles.css" lang="css"></style>
<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.25s;
}
.fade-enter-active {
  transition-delay: 0.25s;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}

@font-face {
  font-family: "Jost";
  src: local("Jost"),   url(./fonts/Jost-Regular.ttf) format("truetype");
  }
  

  @import "~bootstrap/scss/_functions.scss";
  @import "~bootstrap/scss/_variables.scss";
  @import "~bootstrap/scss/mixins/_breakpoints.scss";
  .main_wrapper{
    //background: linear-gradient(to top left, powderblue, rgb(248, 226, 229));
    height: 100vh;
    margin: 0;
    padding: 0;
    .preheader{
      width: 50px;
      min-width: 50px;
      max-width: 50px;
      height: 100vh;
      background: url(assets/header_gradient.jpg);      
    }
    .header{
      position: fixed;
      top: 0;
      left: 0;
      z-index: 10;
      background: white;
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      @include media-breakpoint-down(sm) { 
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        height: auto;
        left: 0;
      }
    }
    .content{
      margin-top: 250px;
      @include media-breakpoint-down(sm) {
        margin-top: 140px;
      }
      //overflow-y: hidden;
    }
  }
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2a5077;

    &.router-link-exact-active {
      color: #ab42b9;
    }
  }
}
</style>
